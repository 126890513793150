import { ref } from "@vue/reactivity";
import { db } from "../firebase/config";
const deleteFromCart = async (doc, del) => {
  const deleteFromCartError = ref(false)
  try {
    const res = await db.collection('users').doc(doc).get()
    if (res.exists) {
      const data = res.data()
      data.cart.splice(del, 1)
      await db.collection('users').doc(doc).update("cart", data.cart)
    }
  } catch (error) {
    deleteFromCartError.value = error.message
  }
  return { deleteFromCartError }
}
export default deleteFromCart