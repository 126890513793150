import { ref } from "@vue/reactivity"
import { db } from "../firebase/config"

const updateCart = async (doc, list, index, property, value) => {
    const cart = ref([])
    const data = ref(null)
    const updateCartError = ref(false)
    try {
        const res = await db.doc(doc).get()
        if (res.exists) {
            data.value = res.data()
            cart.value = data.value[list]
        }
        cart.value = cart.value.map((item, i) => {
            if (i === index) {
                return { ...item, [property]: value }
            } else {
                return item
            }
        })
        console.log(index);
        await db.doc(doc).update("cart", cart.value)

    } catch (error) {
        updateCartError.value = error.message
    }
    return { updateCartError }
}
export default updateCart