<template>
  <!-- eslint-disable -->
  <div class="p-grid p-mx-3 p-my-2 p-pr-2 item">
    <img class="p-lg-1 p-md-2 p-col-3" :src="photo" alt="no photo" />
    <h3 class="p-lg-8 p-md-6 p-col-4">{{ item }}</h3>
    <div class="p-lg-2 p-md-2 p-col-3">
      <div v-if="cart">
        <Button
          label="Delete"
          class="p-button-danger p-m-1"
          @click="deleteItem"
        />
        <Button
          label="Details"
          class="p-button-warning p-m-1"
          @click="details = true"
        />
      </div>
      <div v-else>
        <Button label="Done" class="p-button-danger p-m-1" @click="done" />
        <Button
          label="Details"
          class="p-button-warning p-m-1"
          @click="details = true"
        />
        <Button
          label="See Order"
          class="p-button-warning p-m-1"
          @click="orderDialog = !orderDialog"
        />
      </div>
    </div>
  </div>
  <Dialog
    header="Your Customization"
    v-model:visible="details"
    :style="{ width: '50vw' }"
  >
    <h2>Color:</h2>
    <p>{{ color }}</p>
    <hr />
    <h2>Message:</h2>
    <p>{{ message }}</p>
  </Dialog>
  <Dialog
    header="Order Details"
    v-model:visible="orderDialog"
    :style="{ width: '100vw' }"
  >
    <div class="p-d-flex">
      <img :src="photo" alt="no photo">
      <div class="p-ml-3">
        <p><b>Branch:</b> {{item}}</p>
        <p><b>Code:</b> {{getItem}}</p>
        <p><b>Message:</b> {{message}}</p>
        <p><b>Color:</b> {{color}}</p>
      </div>
    </div>
  </Dialog>
</template>

<script>
import deleteFromCart from "../modules/deleteFromCart";
import { auth, db } from "../firebase/config";
import { useRouter } from "vue-router";
import { ref } from "@vue/reactivity";
import updateCart from "../modules/updateCart";
import { onMounted, watchEffect } from "vue";
export default {
  props: [
    "photo",
    "item",
    "price",
    "index",
    "color",
    "message",
    "cart",
    "order",
    "id",
  ],
  emits: ["done"],
  setup(props, { emit }) {
    const router = useRouter();
    const details = ref(false);
    const orderDialog = ref(false);
    const getItem = ref(null)
    const deleteItem = async () => {
      let doc = `${auth.currentUser.email}-${auth.currentUser.uid}`;
      const { deleteFromCartError } = await deleteFromCart(doc, props.index);
      if (!deleteFromCartError.value) {
        router.go();
      } else {
        window.alert(deleteFromCartError.value);
      }
    };
    const done = async () => {
      let doc = `users/${props.id}/orders/${props.order}`;
      console.log(doc);
      const { updateCartError } = await updateCart(
        doc,
        `cart`,
        props.index,
        "completed",
        "yes"
      );
      if (!updateCartError.value) {
        emit("done");
      }
    };
    onMounted(async () => {
      if (!props.cart) {
        let res = await db.doc(`users/${props.id}/orders/${props.order}`).get()
        let x = res.data().cart.find(e => e.photo === props.photo)
        getItem.value = x.code.split("gs://agape4acc-8ef5f.appspot.com/branches/")[1]
      }
    })
    return { deleteItem, details, done, orderDialog, getItem };
  },
};
</script>

<style scoped>
.item {
  background: rgba(217, 219, 91, 0.39);
}
</style>
