import { ref } from "vue"
import { db } from "../firebase/config"

const getCart = async (doc) => {
    const item = ref([])
    const getCartError = ref(null)
    try {
        const res = await db.doc(`users/${doc}`).get()
        if (res.exists) {
            const data = res.data()
            data.cart.forEach(t => {
                item.value = [...item.value, t]
            });
        }
    } catch (error) {
        getCartError.value = error.message
    }
    return { item, getCartError }
}
export default getCart